.country-select:not(.country-select--flush) .country-select__input-group__flag {
  min-width: 2.75em;
}.country-select__overlay-content {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  overflow-y: scroll;
}
.country-select__overlay-content .country-select__no-matches {
  padding: 0.75rem 1.25rem;
  color: #6c757d;
  background-color: #fff;
}.country-select__list {
  list-style: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}.country-select__list-item {
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-width: 0 0 1px;
  color: #212529;
}
.country-select__list-item.active, .country-select__list-item:hover {
  background-color: #f8f9fa;
}
.country-select__list-item .country-select__flag {
  margin-right: 1em;
  vertical-align: baseline;
}